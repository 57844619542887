<template>
  <div style="position: relative;height: 100%;">
    <Drawer
      width="700"
      v-model="showFormItemDrawer"
      :title="title"
      @on-close="handleDropdownClose">
      <Form
        class="customFromStyle"
        ref="formValidate"
        :model="formItem"
        :rules="formItem.menus_type === 0 ? ruleValidate : apiUrlValidate"
        :label-width="110">
        <FormItem label="类型:">
          侧边栏菜单
        </FormItem>
        <FormItem v-if="formItem.pid !== 0" label="父级:">
          <Cascader :data="parentLevelData" :value="parentLevel" change-on-select />
        </FormItem>
        <FormItem label="菜单类型:">
          <RadioGroup v-model="formItem.menus_type">
            <Radio :label="0">菜单</Radio>
            <Radio :label="1">按钮</Radio>
            <Radio :label="2">接口</Radio>
          </RadioGroup>
        </FormItem>
        <template v-if="formItem.menus_type === 0">
          <FormItem label="打开方式:">
            <RadioGroup v-model="formItem.target">
              <Radio :label="0">组件</Radio>
              <Radio :label="1" disabled>内链</Radio>
              <Radio :label="2" disabled>外链</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="标题:" prop="title">
            <Input v-model="formItem.title" placeholder="请输入路由标题，必填" />
          </FormItem>
          <FormItem label="副标题:">
            <Input v-model="formItem.subhead" placeholder="请输入路由副标题，选填" />
          </FormItem>
          <FormItem label="图标:">
            <IconTools v-model="formItem.icon" />
          </FormItem>
          <FormItem>
            <template #label>
              {{ formItem.target === 2 ? '外链地址' : '路由地址' }}
              <Tooltip v-if="formItem.target === 2" content="需要以`http://`、`https://`、`//`开头" placement="bottom">
                <Icon type="md-help-circle" />
              </Tooltip>
            </template>
            <Input v-model="formItem.path" :placeholder="formItem.target === 2 ? '请输入外链地址, 必填' : '请输入路由地址, 必填'">
              <template #prepend>
                <span>{{$config.routePre}}</span>
              </template>
            </Input>
          </FormItem>
          <FormItem>
            <template #label>
              {{ formItem.target === 1 ? ' 内链地址:' : ' 组件路径:' }}
              <Tooltip v-if="formItem.target === 1" content="需要以`http://`、`https://`、`//`开头" placement="bottom">
                <Icon type="md-help-circle" />
              </Tooltip>
            </template>
            <Input v-model="formItem.component" :disabled="formItem.type === 1 || formItem.target === 2" :placeholder="formItem.target === 1 ? '请输入内链地址' : '请输入路由组件路径，必填'" />
          </FormItem>
          <!--<FormItem>-->
          <!--  <template #label>-->
          <!--    权限节点:-->
          <!--    <Tooltip max-width="250" content="勾选后系统默认创建所需必备的子节点，其它需手动添加" placement="bottom">-->
          <!--      <Icon type="md-help-circle" />-->
          <!--    </Tooltip>-->
          <!--  </template>-->
          <!--  <Select-->
          <!--    v-model="formItem.permissions_nodes"-->
          <!--    :disabled="formItem.menus_type !== 0 || formItem.menus_type !== 2"-->
          <!--    multiple-->
          <!--    :max-tag-count="4">-->
          <!--    <Option v-for="item in permissionsNodesData" :value="item.value" :key="item.value">{{ item.title }}</Option>-->
          <!--  </Select>-->
          <!--</FormItem>-->
        </template>
        <template v-if="formItem.menus_type === 1">
          <FormItem label="按钮名称:" prop="title">
            <Input v-model="formItem.title" placeholder="请输入路由标题，必填" />
          </FormItem>
          <FormItem label="接口地址:" prop="api_url">
            <Input v-model="formItem.api_url" placeholder="请输入路由标题，必填" />
          </FormItem>
        </template>
        <template v-if="formItem.menus_type === 2">
          <FormItem label="接口名称:" prop="title">
            <Input v-model="formItem.title" placeholder="请输入路由标题，必填" />
          </FormItem>
          <FormItem label="接口地址:" prop="api_url">
            <Input v-model="formItem.api_url" placeholder="请输入路由标题，必填" />
          </FormItem>
          <FormItem label="请求方式:" prop="methods">
            <Select v-model="formItem.methods" clearable placeholder="请选择接口的请求方式">
              <Option value="GET" label="GET">
                <span>GET</span>
                <span style="float:right;color:#ccc">获取请求</span>
              </Option>
              <Option value="POST" label="POST">
                <span>POST</span>
                <span style="float:right;color:#ccc">表单请求</span>
              </Option>
              <Option value="PUT" label="PUT">
                <span>PUT</span>
                <span style="float:right;color:#ccc">更新请求</span>
              </Option>
              <Option value="DELETE" label="DELETE">
                <span>DELETE</span>
                <span style="float:right;color:#ccc">删除请求</span>
              </Option>
            </Select>
          </FormItem>
        </template>
        <FormItem label="排序:" prop="sort">
          <Input v-model.number="formItem.sort" :disabled="formItem.target === 1" placeholder="请输入排序，必填" />
        </FormItem>
        <template v-if="formItem.menus_type === 0">
          <FormItem prop="unique_auth">
            <template #label>
              权限标识:
              <Tooltip transfer max-width="250" content="此配置必须保证权限标识是唯一性, 格式：({可自定义如:system}:{可自定义如:menus}:{可自定义如:save})" placement="bottom">
                <Icon type="md-help-circle" />
              </Tooltip>
            </template>
            <Input v-model="formItem.unique_auth" placeholder="请输入标识，必填">
              <template #prepend>
                <span>{{$config.uniquePrefix}}</span>
              </template>
            </Input>
          </FormItem>
        </template>
        <template v-if="formItem.menus_type === 1 || formItem.menus_type === 2">
          <FormItem prop="unique_auth">
            <template #label>
              权限标识:
              <Tooltip max-width="250" content="此配置必须保证权限标识是唯一性, 格式：(system:{自定义}:list{可自定义例如save等})" placement="bottom">
                <Icon type="md-help-circle" />
              </Tooltip>
            </template>
            <Input v-model="formItem.unique_auth" placeholder="请输入标识，必填">
              <template #prepend>
                <span>{{$config.uniquePrefix}}</span>
              </template>
            </Input>
          </FormItem>
        </template>
        <!-- <FormItem label="菜单归属:">
          <RadioGroup v-model="formItem.affiliation">
            <Radio label="facilitator">服务商</Radio>
            <Radio label="system">系统</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="是否可见:">
          <RadioGroup v-model="formItem.hide">
            <Radio :label="0">显示</Radio>
            <Radio :label="1">隐藏</Radio>
          </RadioGroup>
        </FormItem> -->
        <FormItem label="状态:">
          <RadioGroup v-model="formItem.status">
            <Radio :label="0">正常</Radio>
            <Radio :label="1">禁用</Radio>
          </RadioGroup>
        </FormItem>
        <template v-if="formItem.menus_type === 0">
          <FormItem label="备注:">
            <Input v-model="formItem.note" type="textarea" :autosize="{minRows: 3,maxRows: 5}" placeholder="请输入备注" />
          </FormItem>
        </template>
      </Form>
      <div class="drawer-footer">
        <Button type="primary" @click="handleSubmit('formValidate')" v-auths="[`${$config.uniquePrefix}sys:supermanMenus:view:put`]">提交</Button>
      </div>
    </Drawer>
  </div>
</template>

<script>
import {
  Addsuperman_menus
} from '@/api/system'

export default {
  name: 'menusEdit',
  props: {
    // 修改回显的数据
    menusData: Object,
    title: {
      type: String,
      default: '添加顶栏菜单'
    },
    // 联级默认数据
    parentLevel: {
      type: Array,
      default: () => []
    },
    // 联级数据
    parentLevelData: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    menusData (value) {
      this.formItem = Object.assign(this.formItem, value)
    }
  },
  data () {
    return {
      showFormItemDrawer: false,
      // 抽屉中间层样式
      styles: {
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static'
      },
      // 表单数据
      formItem: {
        pid: 0,
        title: '',
        subhead: '',
        icon: '',
        path: '',
        api_url: '',
        methods: '',
        component: '',
        unique_auth: '',
        permissions_nodes: [],
        sort: 0,
        target: 0,
        status: 0,
        is_show: 0,
        note: '',
        menus_type: 0
      },
      // 表单验证规则
      ruleValidate: {
        title: [
          { required: true, message: '请输入菜单标题名称', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请输入排序', type: 'number', trigger: 'blur' }
        ],
        unique_auth: [
          { required: true, message: '请输入权限标识', trigger: 'blur' }
        ]
      },
      apiUrlValidate: {
        title: [
          { required: true, message: '请输入菜单标题名称', trigger: 'blur' }
        ],
        api_url: [
          { required: true, message: '请输入接口地址', trigger: 'blur' }
        ],
        methods: [
          { required: true, message: '请选择接口请求方式', trigger: 'change' }
        ],
        sort: [
          { required: true, message: '请输入排序', type: 'number', trigger: 'blur' }
        ],
        unique_auth: [
          { required: true, message: '请输入权限标识', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 处理添加顶栏抽屉关闭事件
    handleDropdownClose () {
      return new Promise(resolve => {
        this.$Modal.confirm({
          title: '关闭抽屉',
          content: '您是否确认取消本次添加？',
          loading: false,
          onOk: () => {
            this.$parent.initializes()
            this.formItem = {
              pid: 0,
              title: '',
              subhead: '',
              icon: '',
              path: '',
              api_url: '',
              methods: '',
              component: '',
              unique_auth: '',
              permissions_nodes: [],
              sort: 0,
              target: 0,
              status: 0,
              is_show: 0,
              note: '',
              menus_type: 0
            }
          }
        })
      })
    },
    // 处理保存
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          Addsuperman_menus(Object.assign(this.menusData, this.formItem)).then(res => {
            this.$Message.success(res.msg)
            this.$parent.getMenusListRequest()
            this.showFormItemDrawer = false
            this.reload()
            Object.assign(this.$data, this.$options.data())
          }).catch(err => {
            this.$Message.error(err.msg || '保存失败')
          })
        } else {
          // this.$Message.error(this.$t('authorityManagement.menusEdit.validate'))
          this.$Message.error('必填项不可为空哦! ￣へ￣')
        }
      })
    }
  },
  inject: ['reload']
}
</script>

<style scoped>
.customFromStyle {
  overflow-x: auto;
  height: calc(100% - 38px);
}
.drawer-footer{
  /*position: absolute;*/
  /*bottom: 0;*/
  /*left: 0;*/
  width: 100%;
  height: 38px;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.customFromStyle::-webkit-scrollbar {
  width: 0;
}
</style>
